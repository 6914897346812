<template>
  <div>
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <!-- Código -->
          <b-col md="3">
            <b-form-group
              label="Cliente"
              label-for="h-cliente"
            >
              <validation-provider
                #default="{ errors }"
                name="Cliente"
                rules="required"
              >
                <b-form-input
                  id="h-cliente"
                  v-model="cliente.cliente"
                  placeholder="Cliente"
                  maxlength="12"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-input
                  id="h-nome"
                  v-model="cliente.nome"
                  placeholder="Nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="3">
            <b-form-group
              label="NIF"
              label-for="h-nif"
            >
              <validation-provider
                #default="{ errors }"
                name="nif"
                rules="required"
              >
                <b-form-input
                  id="h-nif"
                  v-model="cliente.nif"
                  placeholder="NIF"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group
              label="Nome Fiscal"
              label-for="h-nomeFiscal"
            >
              <validation-provider
                #default="{ errors }"
                name="nomeFiscal"
                rules="required"
              >
                <b-form-input
                  id="h-nomeFiscal Fiscal"
                  v-model="cliente.nomeFiscal"
                  placeholder="Nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="País"
              label-for="pais"
            >
              <validation-provider
                #default="{ errors }"
                name="pais"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="pais"
                    v-model="pais.descricao"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalPais"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-form-group
              label="Morada"
              label-for="h-morada"
            >
              <validation-provider
                #default="{ errors }"
                name="Morada"
                rules="required"
              >
                <b-form-input
                  id="h-morada"
                  v-model="cliente.morada"
                  placeholder="Morada"
                  maxlength="500"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Localidade"
              label-for="h-localidade"
            >
              <validation-provider
                #default="{ errors }"
                name="Localidade"
                rules="required"
              >
                <b-form-input
                  id="h-localidade"
                  v-model="cliente.localidade"
                  placeholder="Localidade"
                  maxlength="500"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Código Postal"
              label-for="h-codigoPostal"
            >
              <validation-provider
                #default="{ errors }"
                name="Código Postal"
                rules="required"
              >
                <b-form-input
                  id="h-codigoPostal"
                  v-model="cliente.codigoPostal"
                  placeholder="Código Postal"
                  maxlength="15"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Telefone"
              label-for="h-telefone"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefone"
                rules="required"
              >
                <b-form-input
                  id="h-telefone"
                  v-model="cliente.telefone"
                  placeholder="Telefone"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Telemovel"
              label-for="h-telemovel"
            >
              <validation-provider
                #default="{ errors }"
                name="Telemovel"
                rules="required"
              >
                <b-form-input
                  id="h-telemovel"
                  v-model="cliente.telemovel"
                  placeholder="Telemovel"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
        </b-row>
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="cliente.email"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email Serviços"
              label-for="emailServicos"
            >
              <validation-provider
                #default="{ errors }"
                name="emailServicos"
                rules="required"
              >
                <b-form-input
                  id="emailServicos"
                  v-model="cliente.emailServicos"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      ref="modalPais"
      hide-footer
      size="xl"
    >

      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Pesquisar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Pesquisar"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columnsPaises"
        :rows="rowsPaises"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'codigo'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.codigo }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'descricao'">

            {{ props.row.descricao }}

          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="selectPais(props.row)"
                >
                  Selecionar
                </b-button>
              </div>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrar 1 até
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap "> de {{ props.total }} registos </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

    </b-modal>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton, BInputGroupPrepend,
  BInputGroup, BPagination, BFormSelect, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BPagination,
    BFormSelect,
    BModal,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    cliente: Object,
    pais: Object,
  },

  data() {
    return {
      required,
      boxTwo: false,
      pageLength: 3,
      searchTerm: '',
      rowsPaises: [],
      columnsPaises: [
        {
          label: 'Código',
          field: 'codigo',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Descrição',
          field: 'descricao',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],

    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getPais()
    localize('pt', pt)
  },
  methods: {
    getPais() {
      axios.get('/api/v1/paises')
        .then(res => { this.rowsPaises = res.data })
      this.$forceUpdate()
    },
    selectPais(row) {
      this.cliente.idPais = row.id
      this.pais.id = row.id
      this.pais.codigo = row.codigo
      this.pais.descricao = row.descricao
      this.hideModalPais()
    },

    showModalPais() {
      this.$refs.modalPais.show()
    },
    hideModalPais() {
      this.$refs.modalPais.hide()
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
