var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Cliente","label-for":"h-cliente"}},[_c('validation-provider',{attrs:{"name":"Cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-cliente","placeholder":"Cliente","maxlength":"12"},model:{value:(_vm.cliente.cliente),callback:function ($$v) {_vm.$set(_vm.cliente, "cliente", $$v)},expression:"cliente.cliente"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"h-nome"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nome","placeholder":"Nome","maxlength":"100"},model:{value:(_vm.cliente.nome),callback:function ($$v) {_vm.$set(_vm.cliente, "nome", $$v)},expression:"cliente.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"NIF","label-for":"h-nif"}},[_c('validation-provider',{attrs:{"name":"nif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nif","placeholder":"NIF","maxlength":"20"},model:{value:(_vm.cliente.nif),callback:function ($$v) {_vm.$set(_vm.cliente, "nif", $$v)},expression:"cliente.nif"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Nome Fiscal","label-for":"h-nomeFiscal"}},[_c('validation-provider',{attrs:{"name":"nomeFiscal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nomeFiscal Fiscal","placeholder":"Nome","maxlength":"100"},model:{value:(_vm.cliente.nomeFiscal),callback:function ($$v) {_vm.$set(_vm.cliente, "nomeFiscal", $$v)},expression:"cliente.nomeFiscal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"País","label-for":"pais"}},[_c('validation-provider',{attrs:{"name":"pais","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"pais","type":"text"},model:{value:(_vm.pais.descricao),callback:function ($$v) {_vm.$set(_vm.pais, "descricao", $$v)},expression:"pais.descricao"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalPais}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Morada","label-for":"h-morada"}},[_c('validation-provider',{attrs:{"name":"Morada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-morada","placeholder":"Morada","maxlength":"500"},model:{value:(_vm.cliente.morada),callback:function ($$v) {_vm.$set(_vm.cliente, "morada", $$v)},expression:"cliente.morada"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Localidade","label-for":"h-localidade"}},[_c('validation-provider',{attrs:{"name":"Localidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-localidade","placeholder":"Localidade","maxlength":"500"},model:{value:(_vm.cliente.localidade),callback:function ($$v) {_vm.$set(_vm.cliente, "localidade", $$v)},expression:"cliente.localidade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Código Postal","label-for":"h-codigoPostal"}},[_c('validation-provider',{attrs:{"name":"Código Postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-codigoPostal","placeholder":"Código Postal","maxlength":"15"},model:{value:(_vm.cliente.codigoPostal),callback:function ($$v) {_vm.$set(_vm.cliente, "codigoPostal", $$v)},expression:"cliente.codigoPostal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":"h-telefone"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-telefone","placeholder":"Telefone","maxlength":"20"},model:{value:(_vm.cliente.telefone),callback:function ($$v) {_vm.$set(_vm.cliente, "telefone", $$v)},expression:"cliente.telefone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Telemovel","label-for":"h-telemovel"}},[_c('validation-provider',{attrs:{"name":"Telemovel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-telemovel","placeholder":"Telemovel","maxlength":"20"},model:{value:(_vm.cliente.telemovel),callback:function ($$v) {_vm.$set(_vm.cliente, "telemovel", $$v)},expression:"cliente.telemovel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","maxlength":"256"},model:{value:(_vm.cliente.email),callback:function ($$v) {_vm.$set(_vm.cliente, "email", $$v)},expression:"cliente.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email Serviços","label-for":"emailServicos"}},[_c('validation-provider',{attrs:{"name":"emailServicos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"emailServicos","type":"email","maxlength":"256"},model:{value:(_vm.cliente.emailServicos),callback:function ($$v) {_vm.$set(_vm.cliente, "emailServicos", $$v)},expression:"cliente.emailServicos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-modal',{ref:"modalPais",attrs:{"hide-footer":"","size":"xl"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Pesquisar")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Pesquisar","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columnsPaises,"rows":_vm.rowsPaises,"rtl":_vm.direction,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'codigo')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.codigo))])]):(props.column.field === 'descricao')?_c('span',[_vm._v(" "+_vm._s(props.row.descricao)+" ")]):(props.column.field === 'action')?_c('span',[_c('span',[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.selectPais(props.row)}}},[_vm._v(" Selecionar ")])],1)])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Mostrar 1 até ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" de "+_vm._s(props.total)+" registos ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }